@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: SCGReg;
  src: url("Font/SCG-Reg.otf") format("opentype");
}

@font-face {
  font-family: SCGBol;
  font-weight: bold;
  src: url("Font/SCG-Bol.otf") format("opentype");
}

/* เวลาข้างซ้ายของ Timeline */
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(48% - 12px);
}

/* status ข้างขวาของ Timeline */
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(52% - 4px);
}

/*'Shipment Tracking Details header's background colour*/
.ant-collapse {
  background-color: #009989;
  border: 1px solid #d9d9d9;
  font-size: 18px;
}

/*font weight of all box's header*/
.ant-collapse-header{
  font-weight: bold;
}

/*font colour of all box's header*/
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white;
}

/*clock icon color*/
.ant-timeline-item-head-blue {
  color: #666666;
  border-color: #1890ff;
}

/*collapse's blackground color*/
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #F0F0F0;
  border-top: 1px solid #d9d9d9;
}

/*timeline trank*/
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  height: calc(100% - 0px);
  border-left: 5px solid #FFFFFF;
}

/*border icon*/
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 5px;
  line-height: 1;
  text-align: center;
  border: 0;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

/*Plusoutlined icon*/
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 18px;
  vertical-align: -1px;
}

/*time in left side of timeline*/
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
  padding-right: 30px;
}

/*custom submit button*/
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 5px;
}

/*custom modal's submit button1*/
.ant-btn {
  border-radius: 5px;
}

/*custom modal's submit button2*/
.ant-modal-footer {
  padding: 10px 16px;
  text-align: center;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  border-radius: 5px;
}

/*disable modal's close button*/
.ant-modal-close-x {
  font-size: 0px;
  width: 0px;
    height: 0px;
}

/*custom modal1*/
.ant-modal-content {
  position: absolute;
  top: 120px;
  width: 500px;
  border-radius: 5px;
}

/*custom modal2*/
.ant-btn {
  padding: 4px 25px;
}

/*user manual botton*/
.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 0px 0 rgb(0 0 0 / 5%);
}

p {
  /* FONT-VARIANT: JIS90; */
 
  margin-bottom: 0.5em;
}

svg {
  overflow: hidden;
  vertical-align: baseline;
}

.alignleft {
	float: left;
}

.alignright {
	float: right;
  margin-right: 12px;
}

.App-link {
  color: #61dafb;
}

.ow-break-word {
   overflow-wrap: break-word;
}

.additional-text {
  position: absolute;
  top: 0;
  right: 50px;
  padding: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}